//TODO: clean up the upload function, hard to read
//and getting maybe unhandled exception errors
function UploaderFactory($rootScope, $http, $log, $q, $window, Upload, jobSubmit, userTokens, SETTINGS) {
  const _url = SETTINGS.apiEndpoint + 'jobs/upload/';
  const proteomicsUrl = SETTINGS.apiEndpoint + 'jobs/proteomics/';
  const uuidUrl = SETTINGS.apiEndpoint + 'jobs/generateJobID/';
  const fragLabel = 'FragPipe File';
  // this corresponds to the s3 aws sdk event name
  // if we employ any other api's, we will still send this event to reduce confusion
  const socketEvent = 'httpDownloadProgress';

  const uploader = {
    allUploadedEvent: 'sq.jobs.upload.service:uploadsComplete',
    running: false,
    // Files we have selected
    files: [],
  };

  uploader.listen = listen;

  uploader.setError = (file, rejection) => {
    if (rejection.name === "TypeError") {
      file.serverError = "Upload was aborted";
    } else {
      let msg;
      if (rejection.status && rejection.status === -1) {
        msg = "Upload aborted or failed to connectivity loss";
      } else if (rejection.status) {
        msg = `${rejection.status}: ${rejection.data || rejection.statusText || rejection.xhrStatus}`;
      } else {
        msg = "Unknown Error";
      }

      file.serverError = msg;
    }
  };

  uploader.generateUUID = () => {
    return $http.get(uuidUrl).then(response => response.data.uuid);
  };

  uploader.cleanup = () => {
    uploader.running = false;
    uploader.finished = false;
    uploader.files = [];
    uploader.job = {};
    uploader.showCheckbox = false;
    uploader.selectAllChecked = false;
    uploader.showPairButton = false;
    uploader.showStitchButton = false;
    uploader.isStitched = false;
    uploader.isPaired = false;
    uploader.selectAllChecked = false;
    uploader.stitchedGeneticFiles = [];
    uploader.submitted = [];
    uploader.linkedFiles = [];
    uploader.remoteFiles = [];
  };

  uploader.cleanup();


  uploader.isGeneticFileStitched = (file) => {
    return uploader.stitchedGeneticFiles.includes(file);
  };

  uploader.uploadCombinedRemoteFiles = (files, jobData) => {
    if (!Array.isArray(files)) {
      const err = new Error('Files must be array. Use a multiple or ngf-multiple="true" attr');
      $log.error(err);
      return Promise.reject(err);
    }

    if (files.length == 0) {
      const err = new Error('No files provided to Uploader');
      $log.error(err);
      return Promise.reject(err);
    }

    return $http.post(_url, { files: files, job: jobData }).then(successResponse => {
      files.forEach((file, idx) => {
        file.data = successResponse.data;
        file.progress = 100;
      });

      return successResponse.data;
    }, rejection => {
      $log.error(rejection);

      files.forEach((file, idx) => {
        file.progress = 0;
        file.serverError = `${rejection.status}: ${rejection.data || "Unknown Error"}`;
      });

      return Promise.reject(rejection);
    });
  };

  uploader.uploadLocalFile = (file, uuid = null, jobData = null) => {
    const data = { file };

    // values, including nulls are sent as strings by ng-file-upload and interpeted as truthy by the api server
    if (uuid) {
      data['uuid'] = uuid;
    }
    if (jobData) {
      data['job'] = JSON.stringify(jobData);
    }

    const uploadPromise = Upload.upload({
      url: _url,
      // doesn't automatically store as JSON
      data: data,
      headers: {
        Authorization: `Bearer ${userTokens.accessToken}`,
      }
    });

    file.upload = uploadPromise;

    return uploadPromise.then((response) => {
      // response.data holds the job object
      file.data = response.data;
      file.progress = 100;

      return response.data;
    }, (rejection) => {
      $log.error(rejection);

      uploader.setError(file, rejection);

      return Promise.reject(rejection);
    }, (event) => {
      $rootScope.$evalAsync(() => {
        $window.requestAnimationFrame(() => {
          file.progress = event.loaded * 100 / event.total;
        });
      });
    });
  };

  uploader.cancelLocalFileUpload = (file => file.upload.abort());

  function _setSocketIOlisteners(socket) {
    $log.debug('setting socket io listeners in jobs.upload.service.js');

    let timeoutPromise;
    socket.on(socketEvent, (response) => {
      $rootScope.$evalAsync(() => {
        const data = JSON.parse(response);
        //TODO: improve performance
        uploader.files.forEach((file) => {
          if (file.name === data.data.name) {
            // We could technically use file.Size as the denominator,
            // but by sending data: total we keep a consistent file upload api
            $window.requestAnimationFrame(() => {
              file.progress = data.data.loaded * 100 / data.data.total;
            });
          }
        });
      });
    });
  }

  // could be dangerous; assumes only this servce listens to these events
  function _removeListeners(socket) {
    socket.removeAllListeners(socketEvent);
  }

  function listen() {
    $rootScope.$on('sq.services.socketIO:unauthorized', (event, socket) => {
      $log.debug('sq.services.socketIO:unauthorized in job.upload.service');
      _removeListeners(socket);
    });

    $rootScope.$on('sq.services.socketIO:authenticated', (event, socket) => {
      $log.debug('sq.services.socketIO:authenticated in job.upload.service');
      _removeListeners(socket);
      _setSocketIOlisteners(socket);
    });

    $rootScope.$on('sq.services.socketIO:disconnected', (event, socket) => {
      $log.debug('sq.services.socketIO:disconnected in job.upload.service');

      _removeListeners(socket);
    });
  }

  return uploader;
}

angular.module('sq.jobs.upload.service', ['ngFileUpload', 'sq.user.auth.tokens', 'sq.jobs.submit.service', 'sq.config'])
  .factory('Uploader', UploaderFactory);